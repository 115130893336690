/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import axios, { AxiosError } from 'axios';
import moment from 'moment';
import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import BaseConfig from '../../config/BaseConfig';
import { ServerError } from '../../models/AxiosModel';

export const ReservationDetail: React.FC = () => {
  const apiClient = axios.create();
  const { t } = useTranslation();
  const [data, setData] = React.useState<any>();

  const getReservationDetail = async () => {
    try {
      const url = window.location.pathname.split('/');
      const reservationGuid = url[url.length - 1];
      const partnerId = BaseConfig.utilities.partnerId();
      const locale = url[1];

      const param = {
        lang: locale,
        reservationGuid,
        partnerId,
      };
      const headers = BaseConfig.utilities.authorizedHeader();
      const response = await apiClient.post(
        BaseConfig.api.user('/api/ReservationManagement/Detail'),
        param,
        {
          headers: JSON.parse(headers),
        },
      );
      setData(response.data);
      return;
    } catch (err) {
      if (err && err.response) {
        const axiosError = err as AxiosError<ServerError>;
        // eslint-disable-next-line no-console
        console.log(axiosError.response?.data);
      }
      throw err;
    }
  };

  useEffect(() => {
    getReservationDetail();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (data) {
    return (
      <>
        <style
          dangerouslySetInnerHTML={{
            __html: `
            html {
              font-family: sans-serif;
              -ms-text-size-adjust: 100%;
              -webkit-text-size-adjust: 100%;
          }
          
          body {
              margin: 0;
          }
          
          article,
          aside,
          details,
          figcaption,
          figure,
          footer,
          header,
          hgroup,
          main,
          menu,
          nav,
          section,
          summary {
              display: block;
          }
          
          audio,
          canvas,
          progress,
          video {
              display: inline-block;
              vertical-align: baseline;
          }
          
              audio:not([controls]) {
                  display: none;
                  height: 0;
              }
          
          [hidden],
          template {
              display: none;
          }
          
          a {
              background-color: transparent;
          }
          
              a:active,
              a:hover {
                  outline: 0;
              }
          
          abbr[title] {
              border-bottom: none;
              text-decoration: underline;
              text-decoration: underline dotted;
          }
          
          b,
          strong {
              font-weight: bold;
          }
          
          dfn {
              font-style: italic;
          }
          
          h1 {
              font-size: 2em;
              margin: 0.67em 0;
          }
          
          mark {
              background: #ff0;
              color: #000;
          }
          
          small {
              font-size: 80%;
          }
          
          sub,
          sup {
              font-size: 75%;
              line-height: 0;
              position: relative;
              vertical-align: baseline;
          }
          
          sup {
              top: -0.5em;
          }
          
          sub {
              bottom: -0.25em;
          }
          
          img {
              border: 0;
          }
          
          svg:not(:root) {
              overflow: hidden;
          }
          
          figure {
              margin: 1em 40px;
          }
          
          hr {
              -webkit-box-sizing: content-box;
              -moz-box-sizing: content-box;
              box-sizing: content-box;
              height: 0;
          }
          
          pre {
              overflow: auto;
          }
          
          code,
          kbd,
          pre,
          samp {
              font-family: monospace, monospace;
              font-size: 1em;
          }
          
          button,
          input,
          optgroup,
          select,
          textarea {
              color: inherit;
              font: inherit;
              margin: 0;
          }
          
          button {
              overflow: visible;
          }
          
          button,
          select {
              text-transform: none;
          }
          
          button,
          html input[type="button"],
          input[type="reset"],
          input[type="submit"] {
              -webkit-appearance: button;
              cursor: pointer;
          }
          
              button[disabled],
              html input[disabled] {
                  cursor: default;
              }
          
              button::-moz-focus-inner,
              input::-moz-focus-inner {
                  border: 0;
                  padding: 0;
              }
          
          input {
              line-height: normal;
          }
          
              input[type="checkbox"],
              input[type="radio"] {
                  -webkit-box-sizing: border-box;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
                  padding: 0;
              }
          
              input[type="number"]::-webkit-inner-spin-button,
              input[type="number"]::-webkit-outer-spin-button {
                  height: auto;
              }
          
              input[type="search"] {
                  -webkit-appearance: textfield;
                  -webkit-box-sizing: content-box;
                  -moz-box-sizing: content-box;
                  box-sizing: content-box;
              }
          
                  input[type="search"]::-webkit-search-cancel-button,
                  input[type="search"]::-webkit-search-decoration {
                      -webkit-appearance: none;
                  }
          
          fieldset {
              border: 1px solid #c0c0c0;
              margin: 0 2px;
              padding: 0.35em 0.625em 0.75em;
          }
          
          legend {
              border: 0;
              padding: 0;
          }
          
          textarea {
              overflow: auto;
          }
          
          optgroup {
              font-weight: bold;
          }
          
          table {
              border-collapse: collapse;
              border-spacing: 0;
          }
          
          td,
          th {
              padding: 0;
          }
          /*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css */
          @media print {
              *,
              *:before,
              *:after {
                  color: #000 !important;
                  text-shadow: none !important;
                  background: transparent !important;
                  -webkit-box-shadow: none !important;
                  box-shadow: none !important;
              }
          
              a,
              a:visited {
                  text-decoration: underline;
              }
          
                  a[href]:after {
                      content: " (" attr(href) ")";
                  }
          
              abbr[title]:after {
                  content: " (" attr(title) ")";
              }
          
              a[href^="#"]:after,
              a[href^="javascript:"]:after {
                  content: "";
              }
          
              pre,
              blockquote {
                  border: 1px solid #999;
                  page-break-inside: avoid;
              }
          
              thead {
                  display: table-header-group;
              }
          
              tr,
              img {
                  page-break-inside: avoid;
              }
          
              img {
                  max-width: 100% !important;
              }
          
              p,
              h2,
              h3 {
                  orphans: 3;
                  widows: 3;
              }
          
              h2,
              h3 {
                  page-break-after: avoid;
              }
          
              .navbar {
                  display: none;
              }
          
              .btn > .caret,
              .dropup > .btn > .caret {
                  border-top-color: #000 !important;
              }
          
              .label {
                  border: 1px solid #000;
              }
          
              .table {
                  border-collapse: collapse !important;
              }
          
                  .table td,
                  .table th {
                      background-color: #fff !important;
                  }
          
              .table-bordered th,
              .table-bordered td {
                  border: 1px solid #ddd !important;
              }
          }
          
          * {
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;
          }
          
              *:before,
              *:after {
                  -webkit-box-sizing: border-box;
                  -moz-box-sizing: border-box;
                  box-sizing: border-box;
              }
          
          html {
              font-size: 10px;
              -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          }
          
          body {
              font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
              font-size: 14px;
              line-height: 1.42857143;
              color: #333333;
              background-color: #ffffff;
          }
          
          input,
          button,
          select,
          textarea {
              font-family: inherit;
              font-size: inherit;
              line-height: inherit;
          }
          
          a {
              color: #337ab7;
              text-decoration: none;
          }
          
              a:hover,
              a:focus {
                  color: #23527c;
                  text-decoration: underline;
              }
          
              a:focus {
                  outline: 5px auto -webkit-focus-ring-color;
                  outline-offset: -2px;
              }
          
          .container {
              padding-right: 15px;
              padding-left: 15px;
              margin-right: auto;
              margin-left: auto;
          }
          
          @media (min-width: 768px) {
              .container {
                  width: 750px;
              }
          }
          
          @media (min-width: 992px) {
              .container {
                  width: 970px;
              }
          }
          
          @media (min-width: 1200px) {
              .container {
                  width: 1170px;
              }
          }
          
          .container-fluid {
              padding-right: 15px;
              padding-left: 15px;
              margin-right: auto;
              margin-left: auto;
          }
          
          .row {
              margin-right: -15px;
              margin-left: -15px;
          }
          
          .row-no-gutters {
              margin-right: 0;
              margin-left: 0;
          }
          
              .row-no-gutters [class*="col-"] {
                  padding-right: 0;
                  padding-left: 0;
              }
          
          .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
              position: relative;
              min-height: 1px;
              padding-right: 15px;
              padding-left: 15px;
          }
          
          @media (min-width: 992px) {
              .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
                  float: left;
              }
          
              .col-md-12 {
                  width: 100%;
              }
          
              .col-md-11 {
                  width: 91.66666667%;
              }
          
              .col-md-10 {
                  width: 83.33333333%;
              }
          
              .col-md-9 {
                  width: 75%;
              }
          
              .col-md-8 {
                  width: 66.66666667%;
              }
          
              .col-md-7 {
                  width: 58.33333333%;
              }
          
              .col-md-6 {
                  width: 50%;
              }
          
              .col-md-5 {
                  width: 41.66666667%;
              }
          
              .col-md-4 {
                  width: 33.33333333%;
              }
          
              .col-md-3 {
                  width: 25%;
              }
          
              .col-md-2 {
                  width: 16.66666667%;
              }
          
              .col-md-1 {
                  width: 8.33333333%;
              }
          }
          
          table {
              background-color: transparent;
          }
          
              table col[class*="col-"] {
                  position: static;
                  display: table-column;
                  float: none;
              }
          
              table td[class*="col-"],
              table th[class*="col-"] {
                  position: static;
                  display: table-cell;
                  float: none;
              }
          
          caption {
              padding-top: 8px;
              padding-bottom: 8px;
              color: #777777;
              text-align: left;
          }
          
          th {
              text-align: left;
          }
          
          .table {
              width: 100%;
              max-width: 100%;
              margin-bottom: 20px;
          }
          
              .table > thead > tr > th,
              .table > tbody > tr > th,
              .table > tfoot > tr > th,
              .table > thead > tr > td,
              .table > tbody > tr > td,
              .table > tfoot > tr > td {
                  padding: 8px;
                  line-height: 1.42857143;
                  vertical-align: top;
                  border-top: 1px solid #dddddd;
              }
          
              .table > thead > tr > th {
                  vertical-align: bottom;
                  border-bottom: 2px solid #dddddd;
              }
          
              .table > caption + thead > tr:first-child > th,
              .table > colgroup + thead > tr:first-child > th,
              .table > thead:first-child > tr:first-child > th,
              .table > caption + thead > tr:first-child > td,
              .table > colgroup + thead > tr:first-child > td,
              .table > thead:first-child > tr:first-child > td {
                  border-top: 0;
              }
          
              .table > tbody + tbody {
                  border-top: 2px solid #dddddd;
              }
          
              .table .table {
                  background-color: #ffffff;
              }
          
          .table-condensed > thead > tr > th,
          .table-condensed > tbody > tr > th,
          .table-condensed > tfoot > tr > th,
          .table-condensed > thead > tr > td,
          .table-condensed > tbody > tr > td,
          .table-condensed > tfoot > tr > td {
              padding: 5px;
          }
          
          .table-bordered {
              border: 1px solid #dddddd;
          }
          
              .table-bordered > thead > tr > th,
              .table-bordered > tbody > tr > th,
              .table-bordered > tfoot > tr > th,
              .table-bordered > thead > tr > td,
              .table-bordered > tbody > tr > td,
              .table-bordered > tfoot > tr > td {
                  border: 1px solid #dddddd;
              }
          
              .table-bordered > thead > tr > th,
              .table-bordered > thead > tr > td {
                  border-bottom-width: 2px;
              }
          
          .table-striped > tbody > tr:nth-of-type(odd) {
              background-color: #f9f9f9;
          }
          
          .table-hover > tbody > tr:hover {
              background-color: #f5f5f5;
          }
          
          .table > thead > tr > td.active,
          .table > tbody > tr > td.active,
          .table > tfoot > tr > td.active,
          .table > thead > tr > th.active,
          .table > tbody > tr > th.active,
          .table > tfoot > tr > th.active,
          .table > thead > tr.active > td,
          .table > tbody > tr.active > td,
          .table > tfoot > tr.active > td,
          .table > thead > tr.active > th,
          .table > tbody > tr.active > th,
          .table > tfoot > tr.active > th {
              background-color: #f5f5f5;
          }
          
          .table-hover > tbody > tr > td.active:hover,
          .table-hover > tbody > tr > th.active:hover,
          .table-hover > tbody > tr.active:hover > td,
          .table-hover > tbody > tr:hover > .active,
          .table-hover > tbody > tr.active:hover > th {
              background-color: #e8e8e8;
          }
          
          .table > thead > tr > td.success,
          .table > tbody > tr > td.success,
          .table > tfoot > tr > td.success,
          .table > thead > tr > th.success,
          .table > tbody > tr > th.success,
          .table > tfoot > tr > th.success,
          .table > thead > tr.success > td,
          .table > tbody > tr.success > td,
          .table > tfoot > tr.success > td,
          .table > thead > tr.success > th,
          .table > tbody > tr.success > th,
          .table > tfoot > tr.success > th {
              background-color: #dff0d8;
          }
          
          .table-hover > tbody > tr > td.success:hover,
          .table-hover > tbody > tr > th.success:hover,
          .table-hover > tbody > tr.success:hover > td,
          .table-hover > tbody > tr:hover > .success,
          .table-hover > tbody > tr.success:hover > th {
              background-color: #d0e9c6;
          }
          
          .table > thead > tr > td.info,
          .table > tbody > tr > td.info,
          .table > tfoot > tr > td.info,
          .table > thead > tr > th.info,
          .table > tbody > tr > th.info,
          .table > tfoot > tr > th.info,
          .table > thead > tr.info > td,
          .table > tbody > tr.info > td,
          .table > tfoot > tr.info > td,
          .table > thead > tr.info > th,
          .table > tbody > tr.info > th,
          .table > tfoot > tr.info > th {
              background-color: #d9edf7;
          }
          
          .table-hover > tbody > tr > td.info:hover,
          .table-hover > tbody > tr > th.info:hover,
          .table-hover > tbody > tr.info:hover > td,
          .table-hover > tbody > tr:hover > .info,
          .table-hover > tbody > tr.info:hover > th {
              background-color: #c4e3f3;
          }
          
          .table > thead > tr > td.warning,
          .table > tbody > tr > td.warning,
          .table > tfoot > tr > td.warning,
          .table > thead > tr > th.warning,
          .table > tbody > tr > th.warning,
          .table > tfoot > tr > th.warning,
          .table > thead > tr.warning > td,
          .table > tbody > tr.warning > td,
          .table > tfoot > tr.warning > td,
          .table > thead > tr.warning > th,
          .table > tbody > tr.warning > th,
          .table > tfoot > tr.warning > th {
              background-color: #fcf8e3;
          }
          
          .table-hover > tbody > tr > td.warning:hover,
          .table-hover > tbody > tr > th.warning:hover,
          .table-hover > tbody > tr.warning:hover > td,
          .table-hover > tbody > tr:hover > .warning,
          .table-hover > tbody > tr.warning:hover > th {
              background-color: #faf2cc;
          }
          
          .table > thead > tr > td.danger,
          .table > tbody > tr > td.danger,
          .table > tfoot > tr > td.danger,
          .table > thead > tr > th.danger,
          .table > tbody > tr > th.danger,
          .table > tfoot > tr > th.danger,
          .table > thead > tr.danger > td,
          .table > tbody > tr.danger > td,
          .table > tfoot > tr.danger > td,
          .table > thead > tr.danger > th,
          .table > tbody > tr.danger > th,
          .table > tfoot > tr.danger > th {
              background-color: #f2dede;
          }
          
          .table-hover > tbody > tr > td.danger:hover,
          .table-hover > tbody > tr > th.danger:hover,
          .table-hover > tbody > tr.danger:hover > td,
          .table-hover > tbody > tr:hover > .danger,
          .table-hover > tbody > tr.danger:hover > th {
              background-color: #ebcccc;
          }
          
          .table-responsive {
              min-height: .01%;
              overflow-x: auto;
          }
          
          @media screen and (max-width: 767px) {
              .table-responsive {
                  width: 100%;
                  margin-bottom: 15px;
                  overflow-y: hidden;
                  -ms-overflow-style: -ms-autohiding-scrollbar;
                  border: 1px solid #dddddd;
              }
          
                  .table-responsive > .table {
                      margin-bottom: 0;
                  }
          
                      .table-responsive > .table > thead > tr > th,
                      .table-responsive > .table > tbody > tr > th,
                      .table-responsive > .table > tfoot > tr > th,
                      .table-responsive > .table > thead > tr > td,
                      .table-responsive > .table > tbody > tr > td,
                      .table-responsive > .table > tfoot > tr > td {
                          white-space: nowrap;
                      }
          
                  .table-responsive > .table-bordered {
                      border: 0;
                  }
          
                      .table-responsive > .table-bordered > thead > tr > th:first-child,
                      .table-responsive > .table-bordered > tbody > tr > th:first-child,
                      .table-responsive > .table-bordered > tfoot > tr > th:first-child,
                      .table-responsive > .table-bordered > thead > tr > td:first-child,
                      .table-responsive > .table-bordered > tbody > tr > td:first-child,
                      .table-responsive > .table-bordered > tfoot > tr > td:first-child {
                          border-left: 0;
                      }
          
                      .table-responsive > .table-bordered > thead > tr > th:last-child,
                      .table-responsive > .table-bordered > tbody > tr > th:last-child,
                      .table-responsive > .table-bordered > tfoot > tr > th:last-child,
                      .table-responsive > .table-bordered > thead > tr > td:last-child,
                      .table-responsive > .table-bordered > tbody > tr > td:last-child,
                      .table-responsive > .table-bordered > tfoot > tr > td:last-child {
                          border-right: 0;
                      }
          
                      .table-responsive > .table-bordered > tbody > tr:last-child > th,
                      .table-responsive > .table-bordered > tfoot > tr:last-child > th,
                      .table-responsive > .table-bordered > tbody > tr:last-child > td,
                      .table-responsive > .table-bordered > tfoot > tr:last-child > td {
                          border-bottom: 0;
                      }
          }
          
          .clearfix:before,
          .clearfix:after,
          .container:before,
          .container:after,
          .container-fluid:before,
          .container-fluid:after,
          .row:before,
          .row:after {
              display: table;
              content: " ";
          }
          
          .clearfix:after,
          .container:after,
          .container-fluid:after,
          .row:after {
              clear: both;
          }
          
          .pull-right {
              float: right !important;
          }
          
          .pull-left {
              float: left !important;
          }
          
          .visible-print {
              display: none !important;
          }
          
          @media print {
              .visible-print {
                  display: block !important;
              }
          
              table.visible-print {
                  display: table !important;
              }
          
              tr.visible-print {
                  display: table-row !important;
              }
          
              th.visible-print,
              td.visible-print {
                  display: table-cell !important;
              }
          }
          
          .visible-print-block {
              display: none !important;
          }
          
          @media print {
              .visible-print-block {
                  display: block !important;
              }
          }
          
          .visible-print-inline {
              display: none !important;
          }
          
          @media print {
              .visible-print-inline {
                  display: inline !important;
              }
          }
          
          .visible-print-inline-block {
              display: none !important;
          }
          
          @media print {
              .visible-print-inline-block {
                  display: inline-block !important;
              }
          }
          
          @media print {
              .hidden-print {
                  display: none !important;
              }
          }
          
          
          
          
          
          
          .container[size="A4"] {
              width: 21cm;
              height: 29.7cm;
          }
          
          @media print {
              .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
                  float: left;
              }
          
              .col-md-12 {
                  width: 100%;
              }
          
              .col-md-11 {
                  width: 91.66666667%;
              }
          
              .col-md-10 {
                  width: 83.33333333%;
              }
          
              .col-md-9 {
                  width: 75%;
              }
          
              .col-md-8 {
                  width: 66.66666667%;
              }
          
              .col-md-7 {
                  width: 58.33333333%;
              }
          
              .col-md-6 {
                  width: 50%;
              }
          
              .col-md-5 {
                  width: 41.66666667%;
              }
          
              .col-md-4 {
                  width: 33.33333333%;
              }
          
              .col-md-3 {
                  width: 25%;
              }
          
              .col-md-2 {
                  width: 16.66666667%;
              }
          
              .col-md-1 {
                  width: 8.33333333%;
              }
          }
          
          .paid-container {
              position: relative;
              text-align: center;
              color: white;
          }
          
          .paidstamp {
              width: 71px;
              height: 70px;
              object-fit: contain;
          }
          
          .paidstamp-content {
              position: absolute;
              top: 50%;
              left: 50%;
              -webkit-transform: translate(-50%, -50%) rotate(-45deg);
              -ms-transform: translate(-50%, -50%) rotate(-45deg);
              transform: translate(-50%, -50%) rotate(-45deg);
              font-size: 14px;
              font-weight: bold;
              line-height: normal;
              letter-spacing: normal;
              color: white;
          }
          
          .row {
              margin-left: -15px;
              margin-right: -15px;
          }
          
          .header {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-orient: horizontal;
              -webkit-box-direction: normal;
              -ms-flex-direction: row;
              flex-direction: row;
              -webkit-box-pack: justify;
              -ms-flex-pack: justify;
              justify-content: space-between;
              margin: 10px 0;
          }
          
              .header .item {
                  -webkit-box-flex: 33.3%;
                  -ms-flex: 33.3%;
                  flex: 33.3%;
              }
          
                  .header .item .title {
                      margin: 0;
                  }
          
          .with-page-break {
              page-break-before: always;
              page-break-after: always;
          }          `,
          }}
        />
        <div className="container" style={{ paddingTop: '30px', paddingBottom: '30px' }}>
          <div className="header">
            <div className="item">
              <img src="http://cdntest.otelz.info/data/logos/otelz_header_logo.png" style={{ maxWidth: '170px' }} className="img" alt="" />
            </div>
            <div className="item">
              <h3 className="title">{t('reservationDetail.newResInfos')}</h3>
            </div>
            <div className="item" style={{ textAlign: 'right' }}><span>&nbsp;</span></div>
          </div>

          <table style={{ width: '100%', marginBottom: 10 }}>
            <tbody>
              <tr style={{ border: '1px solid #ddd' }}>
                <th style={{ padding: 5 }}>
                  <b>
                    {t('reservationDetail.reservationNo')}
                  </b>
                  {' '}
                  :
                  {' '}
                  <b>{data.reservation.reservationNumber}</b>
                </th>
                <th style={{ padding: 5 }}>
                  <b>
                    {data.reservation.customerInfo.name}
                    {' '}
                    {data.reservation.customerInfo.surname}
                  </b>
                </th>
                <th style={{ padding: 5 }}>
                  <b>{t('reservationDetail.tel')}</b>
                  :
                  +
                  <b>{data.reservation.customerInfo.phoneCode}</b>
                  -
                  <b>{data.reservation.customerInfo.phone}</b>
                </th>
                <th
                  style={{
                    padding: 5,
                    maxWidth: '35%',
                    width: '35%',
                    wordBreak: 'break-all',
                  }}
                >
                  <b>{t('Email')}</b>
                  :
                  {' '}
                  <b>{data.reservation.customerInfo.email}</b>
                </th>
              </tr>
            </tbody>
          </table>

          <div className="row">
            <div className="col-md-6">
              <table className="table table-condensed table-bordered">
                <tbody>
                  <tr>
                    <td>
                      <strong>
                        {t('reservationDetail.facilityName')}
                      </strong>
                    </td>
                    <td>
                      <span>{data.reservation.facilityName}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        {t('reservationDetail.parentName')}
                      </strong>
                    </td>
                    <td>
                      <span>
                        {data.reservation.facilityCountryName}
                        {' '}
                        /
                        {' '}
                        {data.reservation.facilityParentName}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        {t('reservationDetail.resDate')}
                        {' '}
                        :
                      </strong>
                    </td>
                    <td>
                      <span>
                        {moment(data.reservation.complatedOn).format('DD.MM.YYYY HH:mm:ss')}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        {t('reservationDetail.checkInDate')}
                        {' '}
                        :
                      </strong>
                    </td>
                    <td>
                      <span>{data.reservation.checkInDateLabel}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        {t('reservationDetail.checkOutDate')}
                        {' '}
                        :
                      </strong>
                    </td>
                    <td>
                      {data.reservation.checkOutDateLabel}
                    </td>
                  </tr>
                  {data.reservation.changeStatus === 1 ? (
                    <>
                      <tr>
                        <td>
                          <strong>
                            {t('reservationDetail.approvedCheckin')}
                            {' '}
                            :
                          </strong>
                        </td>
                        <td>
                          <strong>{moment(data.reservation.approvedCheckIn).format('DD.MM.YYYY')}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            {t('reservationDetail.approvedCheckout')}
                            {' '}
                            :
                          </strong>
                        </td>
                        <td>
                          <strong>{moment(data.reservation.approvedCheckOut).format('DD.MM.YYYY')}</strong>
                        </td>
                      </tr>
                    </>
                  ) : false}
                  <tr>
                    <td>
                      <strong>
                        {t('reservationDetail.reservationStatus')}
                        {' '}
                        :
                      </strong>
                    </td>
                    <td>
                      <span>{data.reservation.statusLabel}</span>
                      {data.reservation.status === 3 ? (
                        <span>
                          --
                          {' '}
                          {moment(data.reservation.canceledOn).format('DD.MM.YYYY HH:mm:ss')}
                        </span>
                      ) : false}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        {t('reservationDetail.totalRooms')}
                        {' '}
                        :
                      </strong>
                    </td>
                    <td>
                      <span>{data.reservation.totalRooms}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        {t('reservationDetail.adults')}
                        {' '}
                        :
                      </strong>
                    </td>
                    <td>
                      <span>{data.reservation.adults}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        {t('reservationDetail.children')}
                        {' '}
                        :
                      </strong>
                    </td>
                    <td>
                      <span>{data.reservation.children}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-md-6">
              <table className="table table-condensed table-bordered">
                <tbody>
                  <tr>
                    <td style={{ width: '70%' }}>
                      <strong>
                        {t('reservationDetail.grossTotal')}
                        {' '}
                        :
                      </strong>
                    </td>
                    <td style={{ width: '30%' }}>
                      <span>{data.reservation.totals.grossLabel}</span>
                    </td>
                  </tr>
                  {data.reservation.totals.tenantDiscountTotal > 0 ? (
                    <tr>
                      <td>
                        <strong>
                          {t('reservationDetail.tenantDiscountTotal')}
                          {' '}
                          :
                        </strong>
                      </td>
                      <td>
                        <span>{data.reservation.totals.tenantDiscountTotalLabel}</span>
                      </td>
                    </tr>
                  ) : false}
                  <tr>
                    <td>
                      <strong>
                        {t('reservationDetail.hotelDiscountTotal')}
                        {' '}
                        :
                      </strong>
                    </td>
                    <td>
                      <span>{data.reservation.totals.hotelDiscountTotalLabel}</span>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} className="info">
                      {data.reservation.paymentType === 10 ?
                        (data.reservation.transactionStatus === 1 ? (
                          <strong>
                            {t('reservationDetail.sumToBePaidTitleWithTax')}
                            {' '}
                            :
                          </strong>
                        ) : (
                          <strong>
                            {t('reservationDetail.sumToBePaidTitle')}
                            {' '}
                            :
                          </strong>
                        ))
                        :
                        (data.reservation.paymentType === 11 ? (
                          <strong>
                            {t('reservationDetail.sumToBePaidTitleWithTax')}
                            {' '}
                            :
                          </strong>
                        ) : (
                          <strong>
                            {t('reservationDetail.sumToBePaidTitle')}
                            {' '}
                            :
                          </strong>
                        ))
                      }
                    </td>
                  </tr>
                  <tr style={{ color: '#5db126' }}>
                    <td>
                      <strong style={{ display: 'block', fontSize: '16px' }}>{t('Net Tutar :')}</strong>
                      {data.reservation.paymentType === 10 || data.reservation.paymentType === 11 ?
                        (data.reservation.transactionStatus === 1 ? (<i>{t('reservationDetail.sumToBePaidOnlineDesc')}</i>) : (<i>{t('reservationDetail.sumToBePaidDesc')}</i>))
                        : (<i>{t('reservationDetail.sumToBePaidDesc')}</i>)}
                    </td>
                    <td style={{ fontSize: '18px' }}>
                      {data.reservation.paymentType === 10 || data.reservation.paymentType === 11 ?
                        (data.reservation.transactionStatus === 1 ? (data.reservation.totals.baseTotalLabel) : (data.reservation.totals.sumToBePaidLabel))
                        : (data.reservation.totals.sumToBePaidLabel)}
                    </td>
                  </tr>
                  {data.reservation.allNotes != null && data.reservation.allNotes.crossRateSalePref
                    ? (
                      <>
                        <tr>
                          <td>
                            <strong>
                              {t('reservationDetail.sumToBePaidQuest')}
                              {' '}
                              :
                            </strong>
                          </td>
                          <td>
                            <span>{data.reservation.totals.sumToBePaidLabel}</span>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            *
                            {' '}
                            {data.reservation.allNotes.crossRate.description}
                          </td>
                        </tr>
                      </>
                    ) : false}

                  {data.reservation.penaltyAmount > 0
                    ? (
                      <>
                        <tr style={{ color: 'red' }}>
                          <td>
                            <strong>{t('reservationDetail.penaltyAmount')}</strong>
                            {data.reservation.status === 3 ?
                              (
                                <i>
                                  (
                                  {t('reservationDetail.cancelPenalty')}
                                  )
                                </i>
                              ) : data.reservation.status === 4 ?
                                (
                                  <i>
                                    (
                                    {t('reservationDetail.noShow')}
                                    )
                                  </i>
                                ) : false}
                          </td>
                          <td>
                            <strong>{data.reservation.penaltyAmountLabel}</strong>
                          </td>
                        </tr>
                      </>
                    )
                    : false}

                </tbody>
              </table>
              <table className="table table-condensed table-bordered">
                <tbody>
                  <tr>
                    <td colSpan={3} className="info">{t('reservationDetail.commissionInfo')}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        {t('reservationDetail.otelzCommissionAmount')}
                        {' '}
                        :
                      </strong>
                    </td>
                    <td colSpan={2}>
                      <span>
                        {data.reservation.totals.chargedCommisionTotalLabel}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        {t('reservationDetail.partnerCommissionRate')}
                        {' '}
                        :
                      </strong>
                    </td>
                    <td colSpan={2}>
                      <span>
                        %
                        {' '}
                        {data.reservation.totals.partnerCommisionRate}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>
                        {t('reservationDetail.partnerCommissionAmount')}
                        {' '}
                        :
                      </strong>
                    </td>
                    <td colSpan={2}>
                      <span>

                        {data.reservation.totals.partnerChargedCommisionTotalLabel}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <table className="table table-condensed table-bordered ">
                <tbody>
                  {(() => {
                    if (data.reservation.paymentType === 10 || data.reservation.paymentType === 11) {
                      return (
                        (() => {
                          if (data.reservation.status === 3) {
                            return (
                              <tr>
                                <td colSpan={2} align="center">
                                  <img src="//img.otelz.com/s3/Uploads/cancel-stamp.png" style={{ width: '300px', objectFit: 'cover' }} />
                                </td>
                              </tr>
                            );
                          } else if (data.reservation.transactionStatus === 1) {
                            return (
                              data.reservation.locale.soldByAffilliate ? (
                                <>
                                  <tr>
                                    <td colSpan={2} align="center">
                                      {data.reservation.locale.soldByAffilliate}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan={2} align="center">
                                      <img src="//img.otelz.com/s3/Uploads/tahsil_edildi_stamp.png" style={{ width: '300px', objectFit: 'cover' }} />
                                    </td>
                                  </tr>
                                </>
                              ) : (
                                <tr>
                                  <td colSpan={2} align="center">
                                    <img src="//img.otelz.com/s3/Uploads/tahsil_edildi_stamp.png" style={{ width: '300px', objectFit: 'cover' }} />
                                  </td>
                                </tr>
                              )
                            );
                          }
                        })()
                      );
                    } else if (data.reservation.paymentType === 20) {
                      return (
                        data.reservation.status === 3 ?
                          (
                            <tr>
                              <td colSpan={2} align="center">
                                <img src="//img.otelz.com/s3/Uploads/cancel-stamp.png" style={{ width: '300px', objectFit: 'cover' }} />
                              </td>
                            </tr>
                          ) :
                          (
                            <tr>
                              <td colSpan={2} align="center">
                                <img src="//img.otelz.com/s3/Uploads/tahsil_edildi_stamp.png" style={{ width: '300px', objectFit: 'cover' }} />
                              </td>
                            </tr>
                          )
                      );
                    }
                  })()}
                  {data.reservation.notes ?
                    (
                      <>
                        {data.reservation.notes.map((note : any) => (
                          note.noteType === 1 || note.noteType === 0 ? (
                            <tr>
                              <td className="span3"><b>Müşteri Notu : </b></td>
                              <td style={{ height: '100px' }}>
                                <div dangerouslySetInnerHTML={{ __html: note.note }} />
                              </td>
                            </tr>
                          ) : (false)
                        ))}
                      </>
                    ) : (false)}

                  {data.reservation.isHoneyMoon ? (
                    <tr className="info">
                      <td colSpan={2}>
                        <b>
                          {t('reservationDetail.honeymoonCouple')}
                          {' '}
                          !
                        </b>
                      </td>
                    </tr>
                  ) : (false)}

                  <tr>
                    <td colSpan={2}>
                      <strong>
                        {t('reservationDetail.discountsTitle')}
                        {' '}
                        :
                      </strong>
                    </td>
                  </tr>

                  {data.reservation.discounts ?
                    (
                      Object.keys(data.reservation.discounts).map((value, index) => (
                        value === 'SuperDiscount' ? (
                          <>
                            <tr>
                              <td colSpan={2}>{data.reservation.discounts[value]}</td>
                            </tr>
                            <tr>
                              <td colSpan={2}>
                                <i style={{ fontSize: '12px;' }}>
                                  *
                                  {t('reservationDetail.superDisDesc')}
                                </i>
                              </td>
                            </tr>
                          </>
                        ) : (
                          <tr>
                            <td colSpan={2}>
                              <div dangerouslySetInnerHTML={{ __html: data.reservation.discounts[value] }} />
                            </td>
                          </tr>
                        )
                      ))
                    )
                    : (false)}

                  {data.reservation.additionalDiscounts ? (
                    <>
                      <tr>
                        <td colSpan={2}>
                          <strong>
                            {t('reservationDetail.additionaldisTitle')}
                            {' '}
                            :
                          </strong>
                        </td>
                      </tr>

                      {data.reservation.additionalDiscounts.map((additionalDis : any) => (
                        <tr>
                          <td colSpan={2}>{additionalDis}</td>
                        </tr>
                      ))}
                    </>
                  ) : (false)}
                </tbody>
              </table>
            </div>
            <div className="col-md-6">
              <table className="table table-bordered table-condensed">
                <tbody>
                  <tr>
                    {(() => {
                      if (data.reservation.allNotes != null && data.reservation.allNotes.cancellation) {
                        return (
                          <>
                            <td><div dangerouslySetInnerHTML={{ __html: data.reservation.allNotes.cancellation.label }} /></td>
                            <td><div dangerouslySetInnerHTML={{ __html: data.reservation.allNotes.cancellation.description }} /></td>
                          </>
                        );
                      // eslint-disable-next-line no-else-return
                      } else if (data.reservation.allNotes != null && data.reservation.allNotes.freeCancel) {
                        return (
                          <>
                            <td><div dangerouslySetInnerHTML={{ __html: data.reservation.allNotes.freeCancel.label }} /></td>
                            <td><div dangerouslySetInnerHTML={{ __html: data.reservation.allNotes.freeCancel.description }} /></td>
                          </>
                        );
                      }
                    })()}
                  </tr>
                  {data.reservation.allNotes != null && data.reservation.allNotes.hourBasedDiscount ? (
                    <>
                      <td><div dangerouslySetInnerHTML={{ __html: data.reservation.allNotes.hourBasedDiscount.label }} /></td>
                      <td><div dangerouslySetInnerHTML={{ __html: data.reservation.allNotes.hourBasedDiscount.description }} /></td>
                    </>
                  ) : (false)}

                  {data.reservation.paymentType !== 10 && data.reservation.paymentType !== 11 && (data.reservation.allNotes !== null && (data.reservation.allNotes.payAtHotel || data.reservation.allNotes.payAfterResDesc)) ? (
                    <>
                      <tr>
                        <td colSpan={2} className="info" style={{ textAlign: 'center' }}>
                          <b>
                            {t('reservationDetail.paymentInfo')}
                          </b>
                        </td>
                      </tr>
                      {data.reservation.allNotes !== null && data.reservation.allNotes.payAtHotel ? (
                        <tr>
                          <td colSpan={2}><div dangerouslySetInnerHTML={{ __html: data.reservation.allNotes.payAtHotel.description }} /></td>
                        </tr>
                      ) : (false)}
                      {data.reservation.allNotes !== null && data.reservation.allNotes.downPayment ? (
                        <tr>
                          <td colSpan={2}><div dangerouslySetInnerHTML={{ __html: data.reservation.allNotes.downPayment.description }} /></td>
                        </tr>
                      ) : (false)}
                      {data.reservation.allNotes !== null && data.reservation.allNotes.payAfterResDesc ? (
                        <tr>
                          <td colSpan={2}><div dangerouslySetInnerHTML={{ __html: data.reservation.allNotes.payAfterResDesc.description }} /></td>
                        </tr>
                      ) : (false)}
                    </>
                  ) : (false)}

                </tbody>
              </table>
            </div>
          </div>

          {data.reservation.totalRooms > 1 ? (
            <div className="row">
              <div className="col-md-12">
                <span>
                  <i style={{ fontSize: '12px' }}>
                    *
                    {t('reservationDetail.roomPriceNote')}
                  </i>
                </span>
              </div>
            </div>
          ) : false
        }

          {data.reservation.rooms.map((value : any) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <div className="row">
              <div className="col-md-12">
                <table className="table table-bordered table-condensed">
                  <tbody>
                    <tr>
                      <td colSpan={2}>
                        <span>
                          <b>
                            {value.order}
                            {' '}
                            .
                            {' '}
                            {t('reservationDetail.room')}
                            {' '}
                            :
                            {' '}
                            {value.roomName}
                          </b>
                          {' '}
                          ,
                          {' '}
                          {value.pensionName}
                          ,
                        </span>
                        <span>
                          <span>
                            {t('reservationDetail.adultText')}
                            {value.children > 0 ? (
                              <span>
                                +
                                {' '}
                                {value.children}
                                {' '}
                                {t('reservationDetail.childText')}
                              </span>
                            )
                              : false}
                            {value.freeChildren > 0 ? (
                              <span>
                                {value.freeChildren}
                                {t('reservationDetail.freechildText')}
                              </span>
                            )
                              : false}
                          </span>
                        </span>
                        {data.reservation.totalRooms > 1 ? (
                          <span>
                            <b style={{ float: 'right' }}>
                              {t('reservationDetail.roomGrossLabel')}
                              :
                              {' '}
                              {value.grossTotalLabel}
                              {' '}
                              ,
                              {' '}
                              {t('reservationDetail.perRoomsNet')}
                              :
                              {' '}
                              {value.netTotalLabel}
                            </b>
                          </span>
                        ) : false}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <p style={{ float: 'left' }}>

                          {value.persons.map((person : any) => (
                            <>
                              <span>
                                <strong>
                                  {person.displayOrder}
                                  .
                                  {t('reservationDetail.guest')}
                                  :
                                </strong>
                                <span>
                                  {person.name}
                                  {' '}
                                  {person.surName}
                                </span>
                              </span>
                              <br />

                            </>
                          ))}
                        </p>
                        <p style={{ textAlign: 'right', float: 'right' }}>
                          <span className="text-block">
                            {t('reservationDetail.smokingPreference')}
                            {' '}
                            :
                            <span>{value.smokingPrefLabel}</span>
                          </span>
                          <br />
                          <span className="text-block">
                            {t('reservationDetail.bedPreference')}
                            {' '}
                            :
                            {value.bedPrefLabel === null || value.bedPrefLabel === '' ? (
                              <>
                                { t('reservationDetail.roomNoBedPref') }
                              </>
                            ) : <>{ value.bedPrefLabel}</>}
                          </span>
                          {value.children > 0 && (value.ages === '' || value.ages === null) ? (
                            <>
                              <br />
                              <span className="text-block">
                                <b>{t('reservationDetail.childAge')}</b>
                                {' '}
                                :
                                {' '}
                                <span>{value.ages}</span>
                              </span>
                            </>
                          ) : false}
                        </p>
                      </td>
                    </tr>
                    {value.hourBased != null ? (
                      <>
                        <tr>
                          <td colSpan={2} className="info"><b>{t('reservationDetail.hourBasedTitle')}</b></td>
                        </tr>
                        <tr>
                          <td>
                            {' '}
                            {value.hourBased.label}
                          </td>
                          <td>{value.hourBased.description}</td>
                        </tr>
                      </>
                    ) : false}
                    <tr>
                      <td colSpan={2} className="info">
                        <b>
                          {t('reservationDetail.cancellationTitle')}
                        </b>
                      </td>
                    </tr>
                    <tr>
                      <td>{value.cancellation.label}</td>
                      <td>{value.cancellation.description}</td>
                    </tr>
                    {value.gifts && value.gifts.length > 0 ? (
                      <>
                        <tr>
                          <td colSpan={2} className="info">
                            <b>
                              {' '}
                              {t('reservationDetail.resGiftsTitle')}
                            </b>
                          </td>
                        </tr>
                        {value.gifts.map((gift : any) => (
                          <tr>
                            <td colSpan={2}>
                              <span>
                                <strong>{gift.label}</strong>
                              </span>
                              {' '}
                              <span className="text-sm gray">{gift.description}</span>
                            </td>
                          </tr>
                        ))}
                      </>
                    )
                      : false}
                    {data.reservation.paymentType === 10 || data.reservation.paymentType === 11 || data.reservation.paymentType === 12 || data.reservation.paymentType === 20 ? (
                      <>
                        <tr>
                          <td colSpan={2} className="info" style={{ textAlign: 'center' }}>
                            Zonline Ticaret ve Turizm Aş.
                            {' '}
                            <b>VD:</b>
                            {' '}
                            Maslak Vergi Dairesi
                            {' '}
                            <b>VN:</b>
                            {' '}
                            998 075 56 37
                            {' '}
                            <b>Tel:</b>
                            {' '}
                            +90 850 255 0 220
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2} className="info" style={{ textAlign: 'center' }}>
                            <b>{t('Adres:')}</b>
                            {' '}
                            Yeşilce Mahallesi, Diken Sokak, No:4/1 34418 - Kağıthane - İstanbul - Türkiye
                          </td>
                        </tr>
                      </>
                    ) : false}
                  </tbody>
                </table>
              </div>
            </div>
          ))}

        </div>
      </>
    );
  }
  return (<span>{t('common.loading')}</span>);
};
